import React from 'react';
export const H1: React.FC<{ className?: string }> = ({
  className = '',
  children,
}) => {
  return (
    <h1
      className={`text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl ${className}`}>
      {children}
    </h1>
  );
};

export const H2: React.FC = ({ children }) => {
  return (
    <h2 className='text-3xl sm:text-4xl md:text-5xl font-extrabold text-gray-900'>
      {children}
    </h2>
  );
};

export const H3: React.FC<{ className?: string }> = ({
  className = '',
  children,
}) => {
  return (
    <h3
      className={`text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 ${className}`}>
      {children}
    </h3>
  );
};

export const Paragraph: React.FC<{ className?: string }> = ({
  className = '',
  children,
}) => {
  return (
    <p
      className={`mt-3 sm:mt-5 md:mt-5 text-base sm:text-lg md:text-xl text-gray-500 ${className}`}>
      {children}
    </p>
  );
};
