import { CheckIcon } from '@heroicons/react/outline';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Helmet } from 'react-helmet';
import { H1, H2, H3, Paragraph } from '../components/typography';

function TopBar() {
  const { language: curLng, languages, originalPath } = useI18next();
  return (
    <div className='relative pt-6 px-4 sm:px-6 lg:px-8'>
      <nav className='relative flex items-center justify-between sm:h-10'>
        <div className='flex items-center flex-grow flex-shrink-0 lg:flex-grow-0'>
          <div className='flex items-center justify-between w-full md:w-auto'>
            <a
              href={process.env.GATSBY_DOMAIN_URL ?? '#'}
              className='flex items-center'>
              <StaticImage
                alt='Beaco Logo'
                className='h-8 w-auto sm:h-10'
                src='../images/logo.png'
                objectFit='contain'
                layout='constrained'
                width={40}
                loading='eager'
              />
              <span className='font-bold text-3xl ml-1 text-indigo-600'>
                beaco
              </span>
            </a>
          </div>
        </div>
        <div className='md:ml-10 md:pr-4 md:space-x-8'></div>
        <div className='md:ml-10 md:pr-4 md:space-x-8 flex items-center'>
          {languages
            .filter((val) => val !== curLng)
            .map((lng) => (
              <div key={lng} className='mr-2'>
                <Link to={originalPath} language={lng}>
                  {lng}
                </Link>
              </div>
            ))}
          <a
            href={process.env.GATSBY_APP_URL ?? '#'}
            className='font-medium text-indigo-600 hover:text-indigo-500'>
            Log in
          </a>
        </div>
      </nav>
    </div>
  );
}

function HeroSection() {
  const { t } = useTranslation();
  return (
    <>
      <div className='max-w-7xl mx-auto'>
        <div className='relative z-10 bg-white lg:w-full md:flex py-8 items-center'>
          <main className='mb-8 md:mb-0 max-w-7xl px-4 sm:px-6 lg:px-8 flex-1'>
            <div className='text-center md:text-left'>
              <H1>
                <Trans
                  i18nKey='heroTitle'
                  components={{
                    main: <span className='block xl:inline' />,
                    bold: <span className='block xl:inline text-indigo-600' />,
                  }}
                />
              </H1>
              <Paragraph className='max-w-xl'>{t('heroDescription')}</Paragraph>
              <div className='mt-5 sm:mt-8 sm:flex justify-center md:justify-start'>
                <div className='rounded-md shadow'>
                  <a
                    href={`${process.env.GATSBY_APP_URL}/sign-up` ?? '#'}
                    className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10'>
                    {t('heroCTA1')}
                  </a>
                </div>
                {/* Demo of the app */}
                <div className='rounded-md shadow ml-0 sm:ml-4 mt-4 sm:mt-0'>
                  <a
                    href='https://youtu.be/6cYXtxTGAx8'
                    target='_blank'
                    className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-100 border-indigo-600 border-2 md:py-4 md:text-lg md:px-10'>
                    {t('heroCTA2')}
                  </a>
                </div>
              </div>
            </div>
          </main>
          <div className='flex flex-1'>
            <StaticImage
              className='mx-auto max-w-xs'
              imgClassName='max-w-sm mx-auto h-screen-0.6'
              src='../images/Screenshot Light.png'
              alt='Screenshot of the app in light mode'
              objectFit='contain'
              layout='constrained'
              width={360}
              loading='eager'
            />
          </div>
        </div>
      </div>
    </>
  );
}

const PriceItem: React.FC<{
  name: string;
  price: string;
  cadence: string;
  description?: string;
}> = ({ name, price, cadence, description }) => {
  return (
    <div className='hover:bg-indigo-100 border-indigo-200 border-2 drop-shadow-sm hover:drop-shadow-lg p-4 text-center md:text-left mb-2'>
      <div className='text-xl font-bold mb-1'>{name}</div>
      <div>
        <span className='text-2xl font-bold text-gray-400 mr-1'>$</span>
        <span className='text-5xl font-bold'>{price}</span>
        <span className='text-2xl font-bold text-gray-400 ml-1'>
          /{cadence}
        </span>
      </div>
      {description && (
        <Paragraph className='text-base sm:text-md md:text-lg mt-1 sm:mt-1 md:mt-1'>
          {description}
        </Paragraph>
      )}
    </div>
  );
};

const ListCheckItem: React.FC = ({ children }) => {
  return (
    <li className='flex items-center mb-2'>
      <CheckIcon className='text-teal-500 w-4 mr-2 min-w-4' />
      <span>{children}</span>
    </li>
  );
};

const Section: React.FC<{ bgColour: string; maxWidth?: string }> = ({
  bgColour,
  maxWidth = 'max-w-3xl',
  children,
}) => {
  return (
    <section className={bgColour}>
      <div className={`${maxWidth} mx-auto`}>
        <div className='w-11/12 lg:w-full mx-auto py-24 items-center'>
          {children}
        </div>
      </div>
    </section>
  );
};

function GetStartedSection() {
  const { t } = useTranslation();
  return (
    <Section bgColour='bg-indigo-100' maxWidth='max-w-5xl'>
      <div className='mx-auto text-center max-w-4xl'>
        <H2>
          <Trans
            i18nKey='getStartedTitle'
            components={{
              br: <br />,
            }}
          />
        </H2>
        <Paragraph>{t('getStartedDescription')}</Paragraph>
        <H3 className='mt-8'>{t('getStartedSubtitle')}</H3>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mt-8'>
        {[
          t('getStartedStep1'),
          t('getStartedStep2'),
          t('getStartedStep3'),
          t('getStartedStep4'),
        ].map((item, index) => (
          <div key={index} className='p-4 text-center'>
            <H3>{index + 1}.</H3>
            <Paragraph>{item}</Paragraph>
          </div>
        ))}
      </div>
    </Section>
  );
}

function PricingSection() {
  const { t } = useTranslation();
  return (
    <Section bgColour='bg-white'>
      <div className='mx-auto text-center'>
        <H2>{t('pricingTitle')}</H2>
        <Paragraph>{t('pricingDescription')}</Paragraph>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-8'>
        <div className='drop-shadow-sm hover:drop-shadow-lg p-4'>
          <div className='text-xl font-bold mb-4'>
            {t('pricingFeatureTitle')}
          </div>
          <ul>
            {[
              t('pricingFeatureListItem1'),
              t('pricingFeatureListItem2'),
              t('pricingFeatureListItem3'),
              t('pricingFeatureListItem4'),
              t('pricingFeatureListItem5'),
            ].map((item, index) => (
              <ListCheckItem key={index}>{item}</ListCheckItem>
            ))}
          </ul>
        </div>
        <div>
          <PriceItem
            name={t('pricingMonthlyName')}
            price={t('pricingMonthlyPrice')}
            cadence={t('pricingMonthlyCadence')}
          />
          <PriceItem
            name={t('pricingYearlyName')}
            price={t('pricingYearlyPrice')}
            cadence={t('pricingYearlyCadence')}
            description={t('pricingYearlyDescription')}
          />
        </div>
      </div>
    </Section>
  );
}

const CTASection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Section bgColour='bg-indigo-800' maxWidth='max-w-5xl'>
      <div className='text-center md:text-left md:flex items-center'>
        <div className='flex-1 md:flex-2'>
          <H1 className='text-white'>{t('ctaTitle')}</H1>
          <Paragraph className='text-indigo-200'>
            {t('ctaDescription')}
          </Paragraph>
        </div>
        <div className='text-center md:text-right flex-1'>
          <div className='rounded-md shadow mt-4 text-center inline-block'>
            <a
              href={`${process.env.GATSBY_APP_URL}/sign-up` ?? '#'}
              className='w-full flex items-center justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-100 md:py-4 md:text-lg md:px-10'>
              {t('ctaButton')}
            </a>
          </div>
        </div>
      </div>
    </Section>
  );
};

function HomePage() {
  const { t } = useTranslation();
  return (
    <div className='relative bg-white overflow-hidden'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Beaco | {t('seoTitle')}</title>
        <meta name='description' content={t('seoDescription')} />
        <link rel='canonical' href={process.env.GATSBY_DOMAIN_URL ?? '/'} />
      </Helmet>
      <TopBar />
      <HeroSection />
      <GetStartedSection />
      <PricingSection />
      <CTASection />
    </div>
  );
}

export default HomePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
